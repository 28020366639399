// extracted by mini-css-extract-plugin
export var qaAchievementsSection = "y_dj";
export var qaBenefitsSection = "y_c7";
export var qaBusinessSection = "y_c8";
export var qaCtaSection = "y_df";
export var qaCtaSection__cta = "y_dg";
export var qaDomainExpertiseSection = "y_dc";
export var qaResultSection = "y_db";
export var qaServicesSection = "y_c4";
export var qaTechStackSection = "y_dd";
export var qaTestimonialPost = "y_c9";
export var qaTestingServicesSection = "y_c5";
export var qaVideoClientSection = "y_dk";
export var qaWhenDoYouNeedSection = "y_c6";
export var qaWhyChooseSection = "y_dh";